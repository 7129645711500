import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [userInput, setUserInput] = useState('');
  const [optionId, setOptionId] = useState('1');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const textareaRef = useRef(null);
  const responseRef = useRef(null);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleOptionChange = (e) => {
    setOptionId(e.target.value);
  };

  const sendRequest = async () => {
    try {
      const res = await axios.post('https://mockapi.easpcki.top/api/chat', {
        userInput,
        optionId,
      });
      setResponse(res.data.response);
    } catch (error) {
      setError('请求出错,请重试。');
      console.error("获取响应出错:", error);
    }
  };

  const handleSubmit = async () => {
    if (!userInput.trim()) {
      setError('请输入内容后再发送。');
      return;
    }
    setLoading(true);
    setError('');
    setResponse('');
    await sendRequest();
    setLoading(false);
  };

  const handleErrorClose = () => {
    setError('');
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [userInput]);

  useEffect(() => {
    if (response && responseRef.current) {
      responseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [response]);

  return (
    <div className="App">
      <h1>AI帮帮忙</h1>
      <div className="input-container">
        <div>
          <select value={optionId} onChange={handleOptionChange}>
            <option value="1">长难句分析小助手</option>
            {/* <option value="2">技术助手</option> */}
            {/* 添加更多选项 */}
          </select>
        </div>
        <div>
          <textarea
            ref={textareaRef}
            value={userInput}
            onChange={handleInputChange}
            placeholder="在此输入您不理解的长难句..."
          />
        </div>
      </div>
      
      <div>
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? 'AI分析中（约1分钟）...' : '发送'}
        </button>
      </div>
      {error && (
        <div className="error">
          {error}
          <button className="close-button" onClick={handleErrorClose}>
            &times;
          </button>
        </div>
      )}
      <div className="divider"></div>
      <div className="response-container" ref={responseRef}>
        <div className="response">
          <h2>回复</h2>
          <pre>{response || '等待您的输入...'}</pre>
        </div>
      </div>
    </div>
  );
}

export default App;